import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}></MDXTag>

export const _frontmatter = {"templateKey":"content-pages","title":"Writing involves choices","key":"writing-involves-choices"};

  